<nav class="navbar navbar-expand-xs mb-3">
  <div class="container-fluid">
    <a class="navbar-brand" href="https://nibio.no" target="_blank">
      <img alt="NIBIO logo" class="navbar-logo-small d-md-none" src="img/NIBIO_logo_hvit.png" />
      <img alt="NIBIO logo" class="navbar-logo hidden-xs d-none d-md-block" src="img/nibio-logo-neg.png" />
    </a>

    <a class="navbar-text" routerLink="/">{{ env.appTitle }}</a>

    <div class="navbar-right">
      <gk-chatbot-button align="right" />
      @if (showLogin()) {
        <gk-bruker-info />
      }
    </div>
  </div>
</nav>
