import { Component, input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BrukerInfoComponent } from '@components/bruker-info/bruker-info.component';
import { ChatbotButtonComponent } from '@components/chatbot-button/chatbot-button.component';
import { environment } from '@environments/environment';

@Component({
  imports: [BrukerInfoComponent, RouterLink, ChatbotButtonComponent],
  selector: 'gk-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  readonly showLogin = input(true);

  env;

  ngOnInit(): void {
    this.env = environment;
  }
}
